@import "../mixins.css";

.component {
  width: 100%;
  height: 100%;

  .paypalButton {
    width: 100%;
    padding: 8px 0px;
    @mixin button_medium;
    @mixin paypal_light_blue_bg;
    margin-top: $spacingNudge;
    border: none;
    appearance: none;
  }

  .svg {
    width: 70px;
  }
}