@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.component {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.input {
  width: 100%;
  position: relative;
  padding: $spacingXs 0 $spacingSm 0;
  overflow: hidden;

  &:first-child {
    padding: 0 0 $spacingSm 0;
  }

  @media (--smartphone) {
    padding: $spacingSm 0;
  }
}

.titleSupplement {
  display: none;
  align-items: center;

  @media (--smartphone) {
    display: flex;
  }
}

.ctaButton {
  border: none;
  @mixin background_primary;
  padding: 0;
  appearance: none;

  &.ctaAfter {
    @mixin captionDescriptionText;
    @mixin text_primary;
    @mixin text_link;
    position: absolute;
    right: $spacingNudge;
    top: $spacingXs;
    @media (--smartphone-and-tablet) {
      top: 18px; /* Specific position required as opposed to token/mixin */
    }
  }
}

/* show previously purchased option, but don't overwrite selected option */
.exchangePurchase:not([class*=selectedItem]) label div {
  @mixin background_primary__disabled;

  &:hover {
    @mixin background_brand__hover;
  }
}

.finalSaleOption {
  display: inline-block;
  @mixin metaDescriptionText;
  @mixin text_tertiary;
  @mixin text_promo;
  text-transform: capitalize;
  @media (--smartphone) {
    font-size: 14px;
  }
}

.finalSaleGroupPadding {
  padding-top: $spacingXs;
}
