@import "../../typography.css";
@import "../../mixins.css";

.component {
  position: relative;
  margin-bottom: $spacingXs;
}

.card {
  @mixin background_primary;
  padding: $spacingSm;
  margin-bottom: $spacingXs;
  border-radius: $spacingNudge;
  @media (--smartphone) {
    margin-bottom: 0;
  }
}

.cardTitle {
  margin: 0 0 $spacingSm;
  @mixin headerText;
  @mixin text_primary;
}

.lineItemWrapper {
  padding: 0;
}

.buttonWrapper {
  position: absolute;
  left: 128px;
  bottom: $spacingSm;
  width: 120px;
  text-align: center;
  padding: none;
}

.fullPrice {
  @mixin text_secondary;
  text-decoration: line-through;
  margin-left: 5px;
}

.salePrice {
  @mixin text_promo;
  margin-left: $spacingNudge;
}

