.component {
  position: relative;
}

.loading {
  cursor: default;
}


.spinner {
  position: absolute;
  right: 5px;
  top: 0;
  width: 50px;
}

.stretch {
  .spinner {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

