@import "../../typography.css";
@import "../../mixins.css";

.remainingOptions {
  @mixin text_error;
  @mixin metaDescriptionText;
  text-align: center;
  margin: 15px auto 20px;
  max-width: 75%;
}
