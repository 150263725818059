@import "../../typography.css";
@import "../../mixins.css";

.passwordContainer {
  position: relative;
}

.emailHeading {
  @mixin headerText;
  @mixin text_primary;
  text-align: center;
}

.withCheckoutPrompt {
  padding: 0px;
}

.showHideButton {
  @mixin metaTitleText;
  @mixin text_secondary;
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 0;
  padding: 8px;
  background: transparent;
  text-decoration: underline;

  &:hover {
    background: transparent;
  }
}