@import "../../typography.css";
@import "../../mixins.css";

.component {
  max-width: 450px;
  justify-content: center;
  margin: 0 auto;
  padding: $spacingMd;
  @media (--desktop) {
      padding: $spacingHuge $spacingMd;
    }
}

.formHeading {
  @mixin titleText;
  @mixin text_primary;
  text-align: center;
}

.formSubHeading {
  @mixin bodyText;
  @mixin text_secondary;
  text-transform: none;
  text-align: center;
  margin: 10px 0 20px;
}

.errorHeading {
  @mixin metaDescriptionText;
  text-align: center;
  @mixin text_error;
  margin: 20px 0;
}

.form {
  margin-bottom: 20px;
}

.submitNote {
  @mixin captionDescriptionText;
  @mixin text_secondary;
  text-align: center;
  margin: 10px auto;

  a {
    @mixin text_primary;  
    text-decoration: none;
  }
}

.requiredText {
  @mixin captionDescriptionText;
  @mixin text_secondary;
  margin: 20px auto;
}

.infoLink {
  @mixin metaDescriptionText;
  @mixin text_primary;
  text-align: center;
  text-transform: none;

  button {
    appearance: none;
    border: none;
    background-color: transparent;
    color: inherit;
    font: inherit;
  }

  a {
    @mixin text_primary;
    font-size: inherit;
    line-height: inherit;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    display: block;
  }
}
