@import "../../mixins.css";
@import "../../colors.css";

.component {
  min-height: 40px;
}

.togglePromoFieldContainer, .promoCodeIsApplied {
  button{
    @mixin metaDescriptionText;
    border: none;
    background: none;
    padding: 0;
    color: $grey7;
  }
}
.togglePromoFieldContainer {
  @mixin metaDescriptionText;
  display: flex;
  align-items: flex-start;
  height: 32px;
  svg {
    font-size: $iconSizeMd;
  }
  button:first-of-type{
    width: 30px;
    display: flex;
    align-items: flex-start;
  }
}

.promoCodeIsApplied {
  @mixin metaDescriptionText;
  button {
    text-decoration: underline;
  }
}
