@import "../../mixins.css";

.page {
}

.drawer {
  @mixin background_secondary;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 20px;
}
