@import "../media.css";
@import "../typography.css";
@import "../mixins.css";

.component {
  width: 100%;
}

.container {
  min-width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--smartphone-and-tablet) {
    min-width: 42px;
    height: 42px;
  }

  &.readOnly:not(.checked) {
    opacity: .6;
    @mixin border_primary;
    border-style: dashed;
  }
}

.text {
  @mixin captionTitleText;
  display: block;
  display: inline-block;
  line-height: 1;
  text-align: center;
  padding: 0 $spacingNudge;
  @media (--smartphone) {
    font-size: 14px;
    line-height: 20px;
  }
}

.unchecked {
  @mixin text_tertiary;
  @mixin background_primary;
  @mixin border_secondary;
  transition: .1s;
  border-radius: 100px;
}

.disabled {
  opacity:0.4;
  background-color: transparent;
  & > span {
      text-decoration: line-through;
  }
}

.checked {
  @mixin background_brand; 
  @mixin text_primary_alternate; 
  @mixin border_brand;
  transition-timing-function: ease-in-out;
  border-radius: 100px;
}

[data-touch-events-supported="false"] {
  .container:not(.disabled, .readOnly):hover {
    cursor: pointer;
  }

  .checked:hover {
    @mixin background_brand__hover;
    @mixin border_brand__hover;
  }

  .unchecked:not(.disabled, .readOnly):hover {
    @mixin text_primary_alternate;
    @mixin background_brand;
    @mixin border_brand;
    transition-timing-function: ease-in-out;
  }
} 
