@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.messageWrapper {
  text-align: left;
  line-height: 16px;
  padding: $spacingSm;
  @mixin background_primary;
  display: flex;
  @mixin captionTitleText;
  margin: 0 0 $spacingXs;
  border-radius: $spacingNudge;
}

.message {
  @mixin text_brand;
  width: 90%;
}

.bundle {

}

.promo {
  .message {
    width: 100%;
  }

  :global .markdown-component {
    p, a {
      text-align: left;
      @mixin captionDescriptionText;
      line-height: 20px;
    }
    em {
      line-height: 20px;
    }
    p {
      margin: 0 0 $spacingXs 0;
      &:last-of-type {
        margin: 0;
      }
    }
    a {
      @mixin text_brand;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.notificationLink {
  @mixin text_brand;
  &:hover {
    text-decoration: underline;
  }
}

.hideMessage, .showMessage {
  cursor: pointer;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.closeButtonWrapper {
  width: 10%;
  display: flex;
  justify-content: flex-end;
}

.noMessageIconCloseButton {
  width: 30%;
}

.closeButton {
  @mixin text_brand;
  height: 19px;
  border: 0;
  padding: 0;
  background: transparent;

  div {
    margin: 0;
  }

  svg {
    font-size: 16px;
  }
}

.closeButton:focus {
  outline: none;
}

.closeIcon {
  @mixin icon_color_secondary;
}
