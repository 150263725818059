@import "../../colors.css";

.component {
  min-height: 40px;
  display: flex;
  margin-bottom: 10px;
}

.input {
  width: 100%;
  height: 100%;
}

.applyButton {
  display: flex;
  align-self: stretch;
  min-width: 75px;
}

.inputWrapper {
  width: 70%;
  height: 100%;

  input {
    border-radius: 0;
    appearance: none;
  }

  input:disabled {
    border: none;
    color: $black;
  }
}

.buttonWrapper {
  width: 30%;

  button {
    appearance: none;
  }
}