@import "../../media.css";

/* requires transforming with postcss-prefix-selector in postcss config to be prefixed with .component :global */
@import "react-id-swiper/src/styles/css/swiper.css";

.component {
  /* nested for specificity (override swiper defaults) */
  .slideWrapper {
    height: 428px;
    min-height: auto;
    width: 285px;
    margin-right: 4px;
    flex: 0 0 auto;
  }
  .slideWrapperSingleImage {
    /* 480px is the width of the right menu and
      150 is the 2:3 aspect ratio since the image is full-width */
    min-height: calc(480px*1.5);
    margin-right: 4px;
    flex: 0 0 auto;
    @media (--smartphone) {
      min-height: 150vw;
    }
  }
}

.image img {
  width: 100%;
}
