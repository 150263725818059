@import "../../typography.css";
@import "../../mixins.css";

.component {
  display: flex;
  justify-content: center;
  align-items: center;
  @mixin background_primary;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: left 0.4s;
  transform: translateZ(0);
  perspective: 1000;
  backface-visibility: hidden;
  padding: $spacingNudge;
}

.left100 {
  &.component {
    left: 100%;
  }
}

.button {
  flex: 1;
  border: none;
  @mixin background_primary;
  @mixin border_right_primary;
  height: 100%;
  margin-right: $spacingNudge;

  &:last-child {
    border: none;
  }

  svg {
    margin-bottom: 8px;
    @mixin icon_color_secondary;
  }

  &.selected {
    svg {
      @mixin icon_color_primary;
    }
  }

  &:hover {
    @mixin text_primary;
    &.selected {
      svg {
        @mixin icon_color_primary;
      }
    }
  }
}
