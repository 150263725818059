@import "../../media.css";
@import "../../typography.css";
@import "../../mixins.css";

.component {
  @mixin background_secondary;
  @mixin text_primary;
  @mixin bodyTitleText;
  display: flex;
  padding: $spacingXs;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  @media (--smartphone) {
    height: auto;
    flex-direction: column;
    padding: 0;
  }
}

.emptyEnter {
  opacity: 0;
}

.top {	
  width: 100%;	
  display: flex;	
  align-items: center;	
  justify-content: space-between;	

  .progressBarContainer {	
    width:80%;	
  }	

  .number {	
    padding: 0 $spacingNudge;	
    @mixin metaTitleText;	
  }	

  .progressBar {	
    @mixin background_secondary;	
    border-radius: 100px;	

    .progress {	
      background: $burgandy;	
      border-radius: 100px;	
      height:7px;	
      transition-delay:0s;	
      transition-duration:0.3s;	
      transition-property:width;	
      transition-timing-function:ease-in-out;	
      max-width: 100%;	
    }	
  }	
}	

.progressText {	
  @mixin metaTitleText;	
  text-align: center;	
  margin-bottom: $spacingNudge;	
}	

.legalText {	
  @mixin captionTitleText;	
  text-align: center;	
  @mixin text_secondary;	
  width: 100%;	
  display: block;	
  padding-bottom: $spacingXs;	
  span {	
    text-decoration: underline;	
  }	
}	


.emptyEnterActive {
  opacity: 1;
  transition: opacity 300ms linear;
}

.lineItemLeave {
  opacity: 1;
}

.lineItemLeaveActive {
  opacity: 0;
  transition: opacity 300ms linear;
}

.lineItems {
  overflow-touch: auto;
  padding: 0;
  flex: 1;
  list-style: none;
  min-height: 150px;
  overflow-y: auto;
  &.cart_double_width {
    @media (--large-tablet-and-desktop) {
      display: inline-block;
      height: 100%;
      overflow-y: auto;
    }
  }
}

.lineItemsList {
  list-style: none;
}

.card {
  @mixin background_primary;
  padding: $spacingSm;
  margin-bottom: $spacingXs;
  border-radius: $spacingNudge;
  @media (--smartphone) {
    margin-bottom: $spacingXs;
    padding: 0 $spacingSm;
  }
}

.cardTitle {
  margin: 0 0 $spacingSm;
  @mixin headerText;
  @mixin text_primary;
  @media (--smartphone) {
    display: none;
  }
}

.empty {
  display: flex;
  padding: 0 $spacingMd;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
  flex-direction: column;
}

.emptyCta {
  margin-bottom: $spacingXs;
  width: 200px;
  @mixin background_primary;
}

.emptyText {
  margin-top: -10%;
  @mixin titleText;
  font-weight: 600;
  margin-bottom: $spacingXs;
}

.emptyDescriptionText {
  @mixin paragraphText;
  margin-bottom: $spacingMd;
}

.empty.notificationPresent {
  position: static;
}

.lineItem {
  @mixin background_primary;
  min-height: 85px;
}

.lineItemLoading {
  position: relative;
  height: 50px;
  padding: $spacingSm;
}

.spinner {
  width: 60px;
  margin: 0 auto;
}

.lineItemLoadingEmpty {
  margin-top: 120px;
}

.footer {
  z-index: 1;
  &.cart_double_width {
    @media (--tablet-and-desktop) {
      display: inline-block;
      height: 100%;
      vertical-align: top;
      width: 50%;
      margin-left: $spacingXs;
    }
  }
  @media (--smartphone) {
    border-top: none;
    @mixin background_primary;
    padding-bottom: $spacingHuge
  }

  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding-bottom: $spacingXs;
    @mixin metaDescriptionText;

    &.total {
      @mixin subheaderText;
      @mixin text_primary;
      padding-top: $spacingXs;
      padding-bottom: $spacingMd;
    }
  }
}

.footerItem {
  padding-top: $spacingSm;
}

.guestCheckoutOptions {
  padding: $spacingXs 0px $spacingNudge 0px;

  span, h4 {
    display: block;
    text-align: center;
  }
  h4 {
    @mixin badgingText;
    font-size: 16px;
    font-weight: 700;
    margin: $spacingNudge 0px 0px 0px;
  }
  span {
    @mixin captionDescriptionText;
    font-size: 14px;
  }
}

.shipping {
  dd {
    text-transform: uppercase;
  }
}

.promoCodeForm form {
  padding-bottom: $spacingSm;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.checkoutForm {
  display: flex;
}

.subtotal {
}

.markdown {
}

.appliedPromo {
}

.bundleDiscount {
}

.storeCredit {
}

.shippingMessage{
  @mixin metaDescriptionText;
  @mixin text_secondary;
}

.affirm {
  text-align: center;
}