@import "../../mixins.css";

.component {
  display: flex;
  justify-content: space-between;
  margin-top: $spacingXs;
  animation: fadein 0.35s ease-in;
  @mixin background_transparent_grey;
}

.image {
  width: 92px;
  margin-right: $spacingNudge;
}

.imageWrapper {
  display: flex;
  align-items: center;
  min-height: 124px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 $spacingXs;
  padding: $spacingSm 0;
  white-space: normal;
  @mixin text_tertiary;
  @mixin metaDescriptionText;
  font-size: 13px;
  width: calc(100% - 92px);
}

.textTitle {
  @mixin metaTitleText;
  @mixin text_primary;
  margin-top: -$spacingXs;
  text-transform: capitalize;
  padding-bottom: $spacingNudge;
}

.closeButtonCTA {
  @mixin metaTitleText;
  @mixin text_tertiary;
  position: absolute;
  right: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  width: 44px;
  height: 44px;
  svg {
    font-size: 16px;
    @mixin icon_color_secondary;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
​
  100% {
    opacity: 1;
  }
}
