@import "../../typography.css";
@import "../../mixins.css";

.component {
  min-height: 40px;
}

.giftNote,
.giftNoteHelperText {
  display: flex;
  justify-content: flex-end;
}

.giftNoteHelperText {
  margin-top: $spacingNudge;
  padding-bottom: $spacingSm;
  @mixin captionDescriptionText;
  @mixin text_secondary;
}
