@import "../../typography.css";
@import "../../mixins.css";

.component {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: $spacingSm 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  @mixin border_top_primary;
}

.thumbnail {
  width: 90px;
}

.details {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: $spacingSm;
  justify-content: flex-start;
  margin-top: $spacingMd;
}

.top {
  display: flex;
  flex-direction: column;
  width: 100%
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  width: 100%
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $spacingXs;
}

.name {
  @mixin bodyTitleText;
  @mixin text_tertiary;
  text-decoration: none;
  margin-bottom: $spacingXs;
}

.description {
  @mixin captionDescriptionText;
  @mixin text_secondary;
  margin-bottom: $spacingNudge;
}

.description2 {
  @mixin captionDescriptionText;
  margin-bottom: $spacingNudge;
}

.priceContainer {
  display: flex;
  justify-content: flex-end;
}

.quanityStockContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hasBundle {
  .soldOut ~ .priceContainer,
  .onlyXLeft ~ .priceContainer {
    text-align: right;
    flex-direction: column;
    .strikethrough {
      margin-right: 0;
    }
  }
}

.price {
  @mixin metaDescriptionText;
  @mixin text_tertiary;
}

.closeButton {
  position: absolute;
  top: $spacingSm;
  right: $spacingXs;
  height: 19px;
  @mixin icon_color_secondary;
  border: 0;
  padding: 0;
  background: transparent;

  div {
    margin: 0;
  }

  svg {
    font-size: 16px;
  }
}

.onlyXLeft,
.soldOut {
}

.quantity {
  @mixin metaDescriptionText;
  display: flex;
  align-items: center;
  @mixin text_secondary;
  height: 36px;
  @mixin border_primary;
  padding: $spacingXs 0;
  width: 90px;
  justify-content: center;
}

.qtyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
}

.qty {
}

.spinner {
  display: inline-block;
  width: 36px;
  height: 36px;
}

.quantityButton {
  @mixin text_secondary;
  border: 0;
  background: transparent;
  height: 38px;
  svg {
    font-size: 12px;
  }
}

.finalSale {
  @mixin captionDescriptionText;
  @mixin text_error;
  margin-bottom: $spacingNudge;
}

.discountedTotal {
  @mixin metaDescriptionText;
  @mixin text_error;
  align-self: flex-end;
}

.strikethrough {
  @mixin text_secondary;
  text-decoration: line-through;
  margin-right: $spacingNudge;
}

.increment{
  svg {
    font-size: 12px;
  }
  padding: 0 $spacingSm 0 0;
}

.decrement{
  padding: 0 0 0 $spacingSm;
}

.message{
  display: block;
  width: 100%;
  @mixin badgeTitleText;
  font-weight: 600;
  text-align: center;
  padding: $spacingNudge $spacingSm;
  @mixin border_primary;
  margin-top: $spacingXs;
}

