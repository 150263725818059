@import "../media.css";
@import "../typography.css";
@import "../mixins.css";
@import "../colors.css";

/* requires transforming with postcss-prefix-selector in postcss config to be prefixed with .component :global */
@import "react-slidedown/lib/slidedown.css";

.component {
  @mixin text_primary;
}

.errorText {
  display: inline-block;
  @mixin text_error;
  @mixin metaDescriptionText;
}

.titleSupplement {
  display: inline-block;
  @mixin metaDescriptionText;
  @mixin text_tertiary;
  text-transform: capitalize;
  @media (--smartphone) {
    font-size: 14px;
  }
}

.onSaleTitleSupplement {
  display: inline-block;
  @mixin metaDescriptionText;
  @mixin text_tertiary;
  text-transform: capitalize;
  @mixin text_promo;
  @media (--smartphone) {
    font-size: 14px;
  }
}

.icon {
  margin-right: $spacingXs;
  vertical-align: text-top;
  font-size: $iconSizeSm;
  @mixin icon_color_primary;
}

.title {
  @mixin metaTitleText;
  margin-right: $spacingNudge;
  display: inline-block;
  @media (--smartphone) {
    font-size: 14px;

    &::after {
      content: ":";
    }
  }
}

.toggleFitList {
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: center;
  align-items: self-start;
  background: #e1e1d9;
  padding: 0 $spacingXs;

  @media (--smartphone) {
    font-size: 14px;
  }
}

.toggleList {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: flex-start;
  padding-top: $spacingXs;

  @media (--smartphone) {
    font-size: 14px;
  }
}

.swatch {
  :global .toggle-item-component {
    margin: 0 $spacingXs $spacingXs 0;
  }
}

.swatch {
  .title::after {
    content: ":";
  }
}

.payments {
  .toggleList {
    justify-content: flex-start;
  }
}

.vertical {
  .toggleList {
    display: block;

    > div:first-child {
      @mixin border_top_primary;
    }
  }
}

.smallCircleInline {
  .toggleList {
    display: block;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chevronButton {
  display: none;
  appearance: none;
  background: none;
  border: none;
  -webkit-tap-highlight-color: transparent;

  @media (--smartphone) {
    display: inline;

    .chevron {
      margin-left: $spacingXs;
      display: inline;
      svg {
          transition: transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
          transform-origin: center;
          transform: rotate(0.75turn);
          width: 15px;
          height: 15px;
          vertical-align: middle;

          path {
              stroke: $black;
          }
      }
      &.chevronUp {
          svg {
              transform: rotate(0.25turn);
          }
      }
    }
  }
}

.disabledToggleGroup {
  opacity: 0.33;
}
