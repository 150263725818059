.component {
  width: 100%;
  display: flex;
}

.inlineWrapper {
  flex-grow: 1;
  padding-right: 10px;
  &:last-of-type {
    padding-right: 0;
  }
}
